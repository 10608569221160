import { useEffect, useState } from 'react';
import './App.css';
import { Divider, Typography } from '@mui/material';
import Header from './components/navigation/NavigationBar';
import { MEDIA } from './assets/images/images';
import { draftTexts } from './i18n/hu-HU';
import SeparateBoxesComponent from './components/TwoCardComponent'
import PictureAndCardComponent from './components/PictureAndCardComponent';
import MembersContainer from './components/MemberContainer';
import EmailFormWithRecaptcha from './components/EmailComponent';
import { useMediaQuery } from '@mui/material';
import { FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SplashScreen from './components/SplashComponent/SplashComponent'
import { splashShowTime } from './utils/utils';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
function App() {
  const isMdOrLower = useMediaQuery('(max-width: 960px)');
  const { t, i18n } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(true);

  // Needed bcause in case of language switch english text might be longer or shorter, and the resize wont happen correctly
  const [prevLanguage, setPrevLanguage] = useState(i18n.language);


  const changeLanguage = (lng) => {
    console.log(i18n.language)
    i18n.changeLanguage(lng);
    setPrevLanguage(lng);

  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstLoad(false);
    }, splashShowTime);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App" style={{ backgroundImage: `url(${MEDIA.backgroundPic})` }}>
      {(firstLoad && isMdOrLower) && <SplashScreen />} {/* Conditionally render SplashScreen */}
      <Header changeLanguage={changeLanguage} />
      <div style={{ paddingTop: isMdOrLower ? 100 : 140, margin: "0 5%" }}>
        <SeparateBoxesComponent showExpandButtonOnDesktop={true} title={`${t('firstCard.title')}`} bulletPoints={`${t('firstCard.bulletPoints', { returnObjects: true })}`} description={`${t('firstCard.description')}`} languageSwitched={prevLanguage} />
        <SeparateBoxesComponent showExpandButtonOnDesktop={true} isDesktopDefaultExpanded={true} title={`${t('secondCard.title')}`} bulletPoints={`${t('secondCard.bulletPoints', { returnObjects: true })}`} description={`${t('secondCard.description')}`} languageSwitched={prevLanguage} />
        <SeparateBoxesComponent showExpandButtonOnDesktop={true} title={`${t('tenders.title')}`} bulletPoints={`${t('tenders.bulletPoints', { returnObjects: true })}`} description={`${t('tenders.description')}`} languageSwitched={prevLanguage} />
        <PictureAndCardComponent showExpandButtonOnDesktop={true} isDesktopDefaultExpanded={true} title={`${t('thirdCard.title')}`} backgroundImageSrc={MEDIA.charterMember} description={`${t('thirdCard.description')}`} subTitle={`${t('thirdCard.subTitle')}`} />
        <SeparateBoxesComponent title={`${t('fourthCard.title')}`} bulletPoints={`${t('fourthCard.bulletPoints', { returnObjects: true })}`} description={`${t('fourthCard.description')}`} showListCss={false} />
        <MembersContainer title={`${t('fifthCard.title')}`} subTitle={`${t('fifthCard.subTitle')}`} members={draftTexts.fifthCard.members.map((member, index) => ({
          name: t(`fifthCard.members.${index}.name`),
          position: t(`fifthCard.members.${index}.position`)
        }))}
          languageSwitched={prevLanguage} />
        <SeparateBoxesComponent title={`${t('sixthCard.title')}`} bulletPoints={`${t('sixthCard.bulletPoints', { returnObjects: true })}`} description={`${t('sixthCard.description')}`} languageSwitched={prevLanguage} />
        <EmailFormWithRecaptcha />
      </div>
      <div style={{ background: 'rgb(182,182,182)', color: 'white', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <div>
          <a target="_blank" href="https://www.instagram.com/ekszer.ora.alapitvany/">
            <FaInstagram size="2em" />
          </a>
          <a target="_blank" href="mailto:info@eoa.hu">
            <MdOutlineMarkEmailUnread size="2em" />
          </a>
        </div>
        <Typography variant="subtitle2" style={{ fontFamily: "BrandingSemilight", fontWeight: "bold" }}> {t('footer')} © {new Date().getFullYear()}</Typography>


      </div>
    </div>
  );
}

export default App;
