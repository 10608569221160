import React, { useState, useEffect } from 'react';
import { MEDIA } from '../../assets/images/images';
import './SplashScreen.css'; // Import your CSS file containing keyframe animation
import { splashShowTime } from '../../utils/utils';

const SplashScreen = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, splashShowTime);

    return () => clearTimeout(timer);
  }, []);

  
  return (
    <div
      className={`splash-screen fade-out`}
    >
      <img src={`${MEDIA.logoWithText}`} alt="Logo" style={{ width: 300, height: 300 }} />
    </div>
  );
};

export default SplashScreen;
