import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar } from '@mui/material';

const PersonCard = ({ name, position, avatarSrc }) => {
  return (
    <Card>
      <CardContent gradient="linear-gradient(to right, rgba(255, 255, 255, 0.5),  rgba(143, 135, 144, 0.5))">
        <Grid container direction="column" alignItems="center" spacing={2}>
          {/* Profile Picture */}
          <Grid item>
            <Avatar alt={name} src={avatarSrc} />
          </Grid>

          {/* Name and Position */}
          <Grid item>
            <Typography variant="h6" align="center" style={{ fontFamily: "BrandingSemilight", fontWeight:"bold"}}>{name}</Typography>
            <Typography variant="subtitle1" align="center" style={{ fontFamily: "BrandingSemilight", fontWeight:""}}>{position}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PersonCard;
