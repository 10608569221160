import React, { useState, useRef, useEffect } from 'react';
import { Collapse, Grid, Paper, Typography, Button, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CustomPaper = styled(Paper)(({ theme, gradient }) => ({
    height: "auto",
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: gradient,
    textOverflow: "ellipsis"
}));

const PictureAndCardComponent = ({ title, description, showExpandButtonOnDesktop, backgroundImageSrc,subTitle, isDesktopDefaultExpanded = true }) => {
    const [expanded, setExpanded] = useState(false);
    const [isDesktopExpanded, setIsDesktopExpanded] = useState(isDesktopDefaultExpanded);
    const firstComponentRef = useRef(null);
    const lastComponentRef = useRef(null);
    const isMdOrLower = useMediaQuery('(max-width: 960px)');
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (backgroundImageSrc) {
            const img = new Image();
            img.onload = () => {
                setImageSize({ width: img.width, height: img.height });
            };
            img.src = backgroundImageSrc;
        }
    }, [backgroundImageSrc]);

    useEffect(() => {
        if (isMdOrLower) return; // execute this effect if not in mobile view
        const handleResize = () => {
            if (firstComponentRef.current && lastComponentRef.current) {
                const firstComponentHeight = firstComponentRef.current.clientHeight;
                lastComponentRef.current.style.height = `${firstComponentHeight}px`;
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrLower, isDesktopExpanded, imageSize]);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const handleDesktopExpand = () => {
        setIsDesktopExpanded(!isDesktopExpanded)
    }
    return (
        <>
            <Divider style={{ marginBottom: isMdOrLower ? 0 : 25 }}>
                <Typography variant="h5" style={{ fontFamily: "BrandingSemilight", fontWeight:"bold", whiteSpace: "initial"}}>{title}</Typography>
                {subTitle && 
                    <Typography variant="h6" style={{ fontFamily: "BrandingSemilight", fontWeight:"bold"}}>{subTitle}</Typography>
                }
                {(!isMdOrLower && showExpandButtonOnDesktop) &&
                    (isDesktopExpanded ?
                        <IconButton onClick={() => handleDesktopExpand()}><KeyboardArrowDownIcon /></IconButton>
                        :
                        <IconButton onClick={() => handleDesktopExpand()}><KeyboardArrowUpIcon /></IconButton>)}
            </Divider>
            <Grid
                container
                spacing={isMdOrLower ? 5 : 1}
            >
                {isMdOrLower &&
                    <>
                        <Grid item lg={4} md={4} xs={12}>
                            <CustomPaper
                                gradient="linear-gradient(to right, rgba(5,5,5, 0.5), rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5))"
                                ref={firstComponentRef}
                                style={{ marginTop: 30, marginBottom: -20, margin: "0 auto", height: imageSize.height/4, width: imageSize.width/4, backgroundImage: `url(${backgroundImageSrc})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat"}}
                            >
                            </CustomPaper>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12} style={{ width: "100%" }}>
                            <Collapse in={expanded}>
                                <CustomPaper
                                    elevation={3}
                                    gradient="linear-gradient(to right, rgba(143, 135, 144, 0.5), rgba(196, 196, 196, 0.5), rgba(255, 255, 255, 0.5))"
                                    ref={lastComponentRef}
                                    className={isDesktopExpanded ? "expanded" : ""}
                                    
                                >
                                    <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left",  fontFamily: "BrandingSemilight", fontWeight:"bold", letterSpacing: 2, lineHeight: 1.5 , padding: 15}}>
                                        {description}
                                    </Typography>
                                </CustomPaper>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            {expanded ?
                                <IconButton><KeyboardArrowUpIcon onClick={handleExpand}></KeyboardArrowUpIcon></IconButton>
                                :
                                <IconButton><KeyboardArrowDownIcon onClick={handleExpand}></KeyboardArrowDownIcon></IconButton>
                            }
                        </Grid>
                    </>
                }
                {(!isMdOrLower && isDesktopExpanded) &&
                    <>
                        <Grid item lg={4} md={4} xs={12} style={{ marginBottom: 25}}>
                            <CustomPaper
                                elevation={3}
                                gradient="linear-gradient(to right, rgba(143, 135, 144, 0.5), rgba(196, 196, 196, 0.5), rgba(255, 255, 255, 0.5))"
                                style={{ overflow: "hidden", minHeight: 300,  height: imageSize.height/3, width: imageSize.width/3, backgroundImage: `url(${backgroundImageSrc})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat"}}
                                ref={firstComponentRef}
                            >
                            </CustomPaper>
                        </Grid>
                        <Grid item lg={8} md={8} xs={12}>
                            <CustomPaper
                                elevation={3}
                                gradient="linear-gradient(to right, rgba(255, 255, 255, 0.5),  rgba(143, 135, 144, 0.5))"
                                style={{ overflow: "hidden", padding: 0, marginLeft: 15 }}
                                ref={lastComponentRef}
                                className={isDesktopExpanded ? "expanded" : ""}
                            >
                                <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", fontFamily: "BrandingSemilight", fontWeight:"bold", letterSpacing: 2, lineHeight: 1.5, padding: 15 }}>
                                    {description}
                                </Typography>
                            </CustomPaper>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    );
};

export default PictureAndCardComponent;
