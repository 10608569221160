import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { draftTexts } from '../i18n/hu-HU';
import { useTranslation } from 'react-i18next';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const RECAPTCHA_SITE_KEY = '6LcFdqkpAAAAAAyORim_jkm4luv6xhiHpEzL0fcA';

const EmailFormWithRecaptcha = () => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const { t } = useTranslation();

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsEmailValid(EMAIL_REGEX.test(newEmail))
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    console.log(t('emailComponentTexts.incorrectFormat'));
    const handleSubmit = () => {
        if (!isEmailValid || !recaptchaValue) {
            alert(t('emailComponentTexts.invalidEmailOrCaptcha'));
            return;
        }

        const requestBody = {
            email: email
        };
    
        fetch('https://api.eoa.hu/api/email-collector', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'recaptcha': recaptchaValue
            },
            body: JSON.stringify(requestBody),
        })
        .then(response => {
            if (!response.ok) {
                if (response.status === 409) {
                    throw  new Error(t('emailComponentTexts.emailExistsError'))
                }
                if (response.status === 500) {
                    throw new Error(t('emailComponentTexts.serverConnectionError'));
                }
                throw new Error(t('emailComponentTexts.unsuccessfullEmailSending'));
            }
            return response.json();
        })
        .then(data => {
            alert(t('emailComponentTexts.thanksForSendingEmail'))
        })
        .catch(error => {
            console.error('Error submitting email:', error);
            alert(error);
        });
    };
    return (
        <Grid container style={{ backgroundColor: "white", background: "radial-gradient(circle, rgba(157,157,157,0.1) 0%, rgba(255,255,255,0) 100%)" }}>
            <Grid xl={12} style={{ paddingTop: 5}}>
                <Typography  variant="h6" style={{ fontFamily: "BrandingSemilight", fontWeight:"bold"}}>{t('emailTextContent.description')}</Typography>
            </Grid>
            
            <Grid item xl={12} xs={12} style={{ padding: 50 }}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    error={!isEmailValid && email.length > 0}
                    helperText={!isEmailValid && email.length > 0 ? `${t('emailComponentTexts.incorrectFormat')}` : ''}
                    style={{ minWidth: 80, maxWidth: 500, borderColor: "pink" }}
                />
            </Grid>
            <Grid xl={12} xs={12}>

                <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleSubmit}
                    disabled={!isEmailValid || !recaptchaValue}
                >
                    {t('emailComponentTexts.buttonText')}
                </Button>

            </Grid>
            <div style={{ margin: "auto", paddingTop: 5 }}>
                <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                />
            </div>
        </Grid>
    );
};

export default EmailFormWithRecaptcha;
