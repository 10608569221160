import React from 'react';
import { styled, useMediaQuery } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { MEDIA } from '../../assets/images/images';
import { draftTexts } from '../../i18n/hu-HU';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Language as LanguageIcon } from '@mui/icons-material';

const HeaderContainer = styled(AppBar)({
  background: 'rgb(182,182,182)',
  width: "100%",
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
});

const Logo = styled(Typography)({
});

const Header = ({ changeLanguage }) => {
  const { t, i18n } = useTranslation();
  const isMobileView = useMediaQuery('(max-width: 660px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    changeLanguage(language);
    handleClose();
  };
  return (
    <HeaderContainer position="fixed" style={{ marginBottom: 100, height: isMobileView ? 85 : "auto" }}>
      <Toolbar>
        <img style={{ width: isMobileView ? 150 : 125, height: isMobileView ? 150 : 125 }} src={`${MEDIA.logo}`} />

        {!isMobileView &&
          <Typography variant="h5" gutterBottom style={{ textAlign: "left", fontFamily: "BrandingSemilight", fontWeight: "bold", letterSpacing: 2, lineHeight: 1.5, padding: 15 }}>
            {t('navigation.title')}
          </Typography>
        }

      </Toolbar>
      
      <div style={{ position: "fixed", right: isMobileView ? 5: 50}}>
          <IconButton
            aria-controls="language-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ color: "black"}}
          >
            <Typography variant="caption">{i18n.language === 'hu' ? 'Nyelv' : 'Language'}</Typography>
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="language-menu"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLanguageSelect('en')}>English</MenuItem>
            <MenuItem onClick={() => handleLanguageSelect('hu')}>Magyar</MenuItem>
          </Menu>
        </div>
    </HeaderContainer>
  );
}

export default Header;
