import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { draftTexts as huDraftTexts } from "./i18n/hu-HU";
import { draftTexts as enDraftTexts} from './i18n/en-EN';
i18n
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            hu: {
                translations: huDraftTexts
            },
            en: {
                translations: enDraftTexts
            }
        },
        fallbackLng: "hu",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: ".", // we use content as keys

        interpolation: {
            format(value, format, lng) {
                if (format === "bold") return value?.toString()?.bold();
                return value;
            }
        }
    });

export default i18n;
