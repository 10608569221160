import { Grid, Divider, Typography } from "@mui/material";
import PersonCard from "./cards/PersonCard";

const MembersContainer = ({title, subTitle, members}) => {
    console.log(members);
    return (
        <>
            <Divider style={{ marginBottom: 25 }}>
                <Typography variant="h5" style={{ fontFamily: "BrandingSemilight", fontWeight:"bold"}}>{title}</Typography>
                <Typography variant="h6" style={{ fontFamily: "BrandingSemilight", fontWeight:"bold"}}>{subTitle}</Typography>
            </Divider>
            <Grid container style={{ paddingTop: 30, paddingBottom: 30}} justifyContent="center" alignItems="center" alignContent="center">
                {members.map((member) => 
                    (
                    <Grid xs={12} md={4} xl={4} style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 10}}>
                       <PersonCard name={member.name} position={member.position}/> 
                    </Grid>
                    )
                )}
            </Grid>
        </>
    )
}

export default MembersContainer;