export const draftTexts = {
    navigation: {
        title: "National Foundation for the Jewelery and Watchmaking Profession"
    },
    firstCard: {
        title: "Foundation's goal",
        bulletPoints: ["Supporting the jewelry and watchmaking profession", "Creating conditions for conveying values", "Establishing a sustainable training system"],
        description: "The primary goal of the foundation is to support, unite, and assist the Hungarian jewelry and watchmaking profession, its representatives, and to nurture the cultural heritage, traditions, and spirit of these professions. The foundation aims to create the conditions necessary to promote the transmission and awareness of intellectual, artistic, environmental, and economic values ​​and traditions to Hungarian society, and to promote the establishment of a sustainable social environment. The foundation also aims to organize extracurricular youth and adult education, support the participation of professionals in competitions and international trade fairs, thereby enhancing Hungary's reputation and recognition."
    },
    secondCard: {
        title: "Foundation's activities",
        bulletPoints: ['Uniting the jewelry and watchmaking profession to increase turnover', "Supporting Hungarian manufacturers by announcing professional tenders", "Supporting entry into foreign markets", "Participation in exhibitions", "Organizing professional lectures and events related to the jewelry and watchmaking profession"],
        description: "During its operation, the foundation aims to reform, unite, and carry out economic development tasks in the field of jewelry and watchmaking both domestically and abroad. To achieve the goals of the foundation",
        descriptionBulletPoints: ["it plans to organize theoretical and practical sessions", "organizes lectures, as well as", "plans to implement professional demonstrations, exhibitions, training sessions, and other events."
        ]
    },
    thirdCard: {
        title: "Founder of the Foundation",
        subTitle: "László Horváth",
        description: "I have been interested in jewelry and the arts, fashion, and art history since my childhood. This trend has determined my whole life so far. From my teens, I was an enthusiastic member of handicraft and creative workshops, circles, and camps, and with the support of my teachers, I won several national competitions. My first exhibition was in 1989 when I was 16. In 2011, I had the opportunity to present my creations, jewelry at several renowned world exhibitions. We returned home with great success and recognition. I have been working in the world of jewelry for 31 years, with minor detours into other artistic fields, but no day has passed without jewelry. During this time, I have always been a jewelry designer - initially a retailer, then a wholesaler - and I became an importer with my own 59-person manufacturing facility. Currently, I am a jewelry, gemstone, watch opinion leader (influencer)."
    },
    fourthCard: {
        title: "Nature of the Foundation",
        bulletPoints: ['Open'],
        description: "The foundation is open, any domestic or foreign natural or legal person can join the Foundation at any time during the existence of the Foundation with a contribution of their choice. Bank account number: 10300002 - 13518522 - 00014909"
    },
    fifthCard: {
        title: "Executive Board",
        subTitle: "Board of Trustees",
        members: [
            { name: "László Kiss", position: "President" },
            { name: "Sándor Sebők", position: "Vice President" },
            { name: "Zoltán Mersitz", position: "Vice President" }
        ]
    },
    sixthCard: {
        title: "Sections",
        bulletPoints: ["Goldsmith/Jeweler", "Commercial", "Hungarian manufacturer", "Wholesaler", "Gemstone", "Junior", "Ethical"]        ,
        description: "The purpose and mission of the sections are to support the work of the professional committees and to actively represent the various professional areas in order to convey the appropriate emphasis on interests to decision-makers. The selection of section presidents and members is in progress."
    },

    emailTextContent: {
        description: "SeparateBoxesComponentI request information regarding applications, professional events, and support for Hungarian manufacturers to the following email address. Furthermore, about all activities of the foundation related to and assisting the profession.",
        caption: "By pressing the SUPPORT! button, you consent to the use of your data for internal statistics. Only the email address will be stored!"
    },
    emailComponentTexts: {
        buttonText: "Send",
        incorrectFormat: "Incorrect email format",
        invalidEmailOrCaptcha: "Provide a valid email address or fill out the check!",
        emailExistsError: "The email address is already in our database!",
        serverConnectionError: "Error connecting to the server.",
        unsuccessfullEmailSending: "Unsuccessful email sending, please reload the page and try again!",
        thanksForSendingEmail: "Thank you for your support!"
    },
    tenders: {
        title: "Our Projects",
        bulletPoints: [
            "Traffic-increasing exhibition and fair",
            "In the field of designer jewelry-watch-ornament",
            "Tender announcement in May 2024"
        ],
        description: "Subscribe to the newsletter to be among the first to be informed!"
    },
    footer: "The information, photos, and videos on the website can only be used with the exclusive written permission of the operator of the site. All rights reserved."
}
